import React, { useCallback, useEffect, useState } from "react";
import { Button, Picker } from "components";
import { Loading } from "components/icons";
import { useApp, useForm } from "hooks";

import validationSchema from "./AddressLookup.validation";
import { API } from "aws-amplify";

function getAddressPlaceholder(addressOptions) {
  if (addressOptions === undefined) {
    return "Search a postcode to find addresses";
  } else if (addressOptions.length === 0) {
    return "No addresses found";
  } else if (addressOptions.length > 0) {
    return `${addressOptions.length} addresses found`;
  }

  return "";
}

export default function AddressFinder({
  postcode = "",
  disableComponent = false,
  pickerDefault = false,
  setPickerDefault = () => {},
  validatePostcode = () => {},
  onChange = () => {},
}) {
  const { addMessage } = useApp();

  const [addressOptions, setAddressOptions] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const { fieldValues, getFieldProps, validateFieldValues, setFieldValue } =
    useForm(
      {
        postcode: "",
        houseNumber: "",
        street: "",
        city: "",
      },
      validationSchema
    );

  const handleSearch = useCallback(
    async (postcode) => {
      fieldValues.postcode = postcode.trim();

      const isValid = await validateFieldValues(fieldValues);

      if (!isValid) {
        setAddressOptions(undefined);
        setLoading(false);
        return;
      }

      setLoading(true);

      try {
        const result = await API.get(
          "UnauthenticatedAPI",
          `/addresses/${fieldValues.postcode}`
        );

        setAddressOptions(
          result.data.map((x) => ({
            title: x.fullAddress,
            value: JSON.stringify(x),
          }))
        );

        setPickerDefault(false);
      } catch (error) {
        console.log(error);
        addMessage(
          "error",
          "An error occurred when searching for addresses. Please try again."
        );
      }

      setLoading(false);
    },
    [fieldValues.postcode]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      onChange &&
      getFieldProps("postcode").value !== "" &&
      addressOptions !== undefined
    ) {
      onChange(fieldValues);
    }
  }, [fieldValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div>
        <div className="mb-5">
          <Picker
            variant="light"
            id="address"
            title="Select Address *"
            disabled={
              addressOptions === undefined ||
              addressOptions.length === 0 ||
              disableComponent
            }
            placeholder={getAddressPlaceholder(addressOptions)}
            defaultValue={pickerDefault && getAddressPlaceholder(undefined)}
            options={addressOptions}
            optionsReset={true}
            onChange={(val) => {
              const address = JSON.parse(val);

              const houseNumber = [
                address.subBuildingName.trim(),
                address.buildingNumber.trim() || address.buildingName,
              ]
                .filter((s) => !!s)
                .join(", ");

              setFieldValue("houseNumber", houseNumber);
              setFieldValue("street", address.thoroughfare);
              setFieldValue("postcode", address.postcode);
              setFieldValue("city", address.town);
            }}
          />
          {loading ? (
            <div className="mt-8">
              <Loading />
            </div>
          ) : (
            <div className="mt-4">
              <Button
                type="button"
                id="search"
                title="Search"
                variant={loading ? "disabled" : "primary"}
                disabled={loading || disableComponent}
                fillStyle="outline"
                onClick={() => {
                  validatePostcode();
                  handleSearch(postcode);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
