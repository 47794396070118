import Canada from "images/flags/canada.png";
import UnitedKingdom from "images/flags/united_kingdom.svg";
import UnitedStates from "images/flags/united_states.png";
import Ireland from "images/flags/ireland.png";

export const LOCALE_UK = "en-GB";
export const LOCALE_IE = "en-IE";
export const LOCALE_US = "en-US";
export const LOCALE_CA = "en-CA";

// Country codes from Salesforce
export const COUNTRY_CODE_UK = "GB";
export const COUNTRY_CODE_IE = "IE";
export const COUNTRY_CODE_US = "US";
export const COUNTRY_CODE_CA = "CA";

// Salesforce preferred brand values only used for North American users
export const SALESFORCE_PREFERRED_BOILER_BRAND_IDEAL = "Ideal Heating";
export const SALESFORCE_PREFERRED_BOILER_BRAND_TRIANGLE_TUBE = "Triangle Tube";
export const SALESFORCE_PREFERRED_BOILER_BRAND_NO_PREFERRENCE =
  "I have no preference/I do not install boilers";

export const CONTENTFUL_BRAND_IDEAL_HEATING_DOMESTIC = "Ideal Heating Domestic";
export const CONTENTFUL_BRAND_IDEAL_HEATING_COMMERCIAL =
  "Ideal Heating Commercial";
export const CONTENTFUL_BRAND_GLEDHILL = "Gledhill";
export const CONTENTFUL_BRAND_ATLANTIC = "Atlantic";
export const CONTENTFUL_BRAND_KESTON = "Keston";
export const CONTENTFUL_BRAND_IDEAL_HEATING_NORTH_AMERICA =
  "Ideal Heating North America";
export const CONTENTFUL_BRAND_TRIANGLE_TUBE = "Triangle Tube";

export const getPostcodeFieldOptions = (country) => {
  switch (country) {
    case COUNTRY_CODE_IE:
      return {
        title: "Eircode",
        subtitle:
          "Please enter a valid Eircode e.g. A65 F4E2 or leave field blank",
      };
    case COUNTRY_CODE_US:
      return {
        title: "ZIP Code *",
        subtitle: "Please enter a valid ZIP Code e.g. 90210",
      };
    case COUNTRY_CODE_CA:
      return {
        title: "Postal Code *",
        subtitle: "Please enter a valid Postal Code e.g. M5V 3L9",
      };
    default:
      return {
        title: "Postcode *",
        subtitle: "Postcodes must include spaces e.g. HU5 4JN",
      };
  }
};

export const localeToContentfulCountryCodes = {
  "en-GB": "GB",
  "en-IE": "ROI",
  "en-CA": "CA",
  "en-US": "US",
};

export function getRegionOptions(countryCode) {
  switch (countryCode) {
    case COUNTRY_CODE_CA:
      return {
        regionTitle: "Province/territory *",
        regionLabel: "Pick an applicable province/territory*",
        regionOptions: canadaRegionOptions,
        regionPlaceholder: "Pick province/territory",
      };
    case COUNTRY_CODE_US:
      return {
        regionTitle: "State *",
        regionLabel: "Pick an applicable state*",
        regionOptions: usRegionOptions,
        regionPlaceholder: "Pick state",
      };
    default:
      return {
        regionTitle: "",
        regionLabel: "",
        regionOptions: [],
        regionPlaceholder: "",
      };
  }
}

export const getRegionTitle = (countryCode, regionVal) => {
  const { regionOptions } = getRegionOptions(countryCode);

  const region = regionOptions.find((region) => region.value === regionVal);
  return region ? region.title : "";
};

export const determineLocaleFromCountry = (countryCode) => {
  switch (countryCode) {
    case COUNTRY_CODE_IE:
      return LOCALE_IE;
    case COUNTRY_CODE_CA:
      return LOCALE_CA;
    case COUNTRY_CODE_US:
      return LOCALE_US;
    default:
      return LOCALE_UK;
  }
};

export const determineCountryCodeFromLocaleOrUser = (user, locale) => {
  if (user.countryCode) return user.countryCode;
  switch (locale) {
    case LOCALE_IE:
      return COUNTRY_CODE_IE;
    case LOCALE_CA:
      return COUNTRY_CODE_CA;
    case LOCALE_US:
      return COUNTRY_CODE_US;
    default:
      return COUNTRY_CODE_UK;
  }
};

export const determineCountryCurrency = (countryCode) => {
  switch (countryCode) {
    case COUNTRY_CODE_CA:
      return "CAD";
    case COUNTRY_CODE_US:
      return "USD";
    case COUNTRY_CODE_IE:
      return "EUR";
    default:
      return "GBP";
  }
};

export const determinePointsMultiplier = (countryCode) => {
  switch (countryCode) {
    case COUNTRY_CODE_CA:
    case COUNTRY_CODE_US:
      return 1;
    case COUNTRY_CODE_IE:
      return 0.4;
    default:
      return 0.33;
  }
};

const ukPropertyTypeOptions = [
  { title: "Bungalow", value: "Bungalow" },
  { title: "Detached", value: "Detached" },
  { title: "End of Terrace", value: "End of Terrace" },
  { title: "Flat", value: "Flat" },
  { title: "Holiday Cottage/Flat", value: "Holiday Cottage/Flat" },
  { title: "House Boat", value: "House Boat" },
  {
    title: "Leisure Accommodation Vehicle",
    value: "Leisure Accommodation Vehicle",
  },
  { title: "Other", value: "Other" },
  { title: "Residential Care Home", value: "Residential Care Home" },
  { title: "Residential Park Home", value: "Residential Park Home" },
  { title: "Semi-Detached", value: "Semi-Detached" },
  { title: "Staff Accommodation", value: "Staff Accommodation" },
  { title: "Terrace", value: "Terrace" },
  { title: "Youth Hostel", value: "Youth Hostel" },
];

const usCanadaPropertyTypeOptions = [
  {
    title: "Residential (Primary Residence) – Single Family",
    value: "Residential (Primary Residence) – Single Family",
  },
  {
    title: "Residential (Second Home) – Single Family",
    value: "Residential (Second Home) – Single Family",
  },
  {
    title: "Residential (Primary Residence) – Two Family Dwelling",
    value: "Residential (Primary Residence) – Two Family Dwelling",
  },
  {
    title: "Residential (Second Home) – Two Family Dwelling",
    value: "Residential (Second Home) – Two Family Dwelling",
  },
  {
    title: "Residential (Primary Residence) – Single Family (High Altitude)",
    value: "Residential (Primary Residence) – Single Family (High Altitude)",
  },
  {
    title: "Residential (Second Home) – Single Family (High Altitude)",
    value: "Residential (Second Home) – Single Family (High Altitude)",
  },
  {
    title:
      "Residential (Primary Residence) – Two Family Dwelling (High Altitude)",
    value:
      "Residential (Primary Residence) – Two Family Dwelling (High Altitude)",
  },
  {
    title: "Residential (Second Home) – Two Family Dwelling (High Altitude)",
    value: "Residential (Second Home) – Two Family Dwelling (High Altitude)",
  },
  {
    title: "Commercial – Multiple Family Dwelling",
    value: "Commercial – Multiple Family Dwelling",
  },
  { title: "Commercial – Business", value: "Commercial – Business" },
  { title: "Commercial – School", value: "Commercial – School" },
  {
    title: "Commercial – Non-Profit Organization",
    value: "Commercial – Non-Profit Organization",
  },
  {
    title: "Commercial – Multiple Family Dwelling (High Altitude)",
    value: "Commercial – Multiple Family Dwelling (High Altitude)",
  },
  {
    title: "Commercial – Business (High Altitude)",
    value: "Commercial – Business (High Altitude)",
  },
  {
    title: "Commercial – School (High Altitude)",
    value: "Commercial – School (High Altitude)",
  },
  {
    title: "Commercial – Non-Profit Organization (High Altitude)",
    value: "Commercial – Non-Profit Organization (High Altitude)",
  },
];

export const propertyTypeOptions = {
  [LOCALE_UK]: ukPropertyTypeOptions,
  [LOCALE_IE]: ukPropertyTypeOptions,
  [LOCALE_CA]: usCanadaPropertyTypeOptions,
  [LOCALE_US]: usCanadaPropertyTypeOptions,
};

const ukBoilerLocationOptions = [
  { title: "Bedroom", value: "Bedroom" },
  { title: "Bathroom", value: "Bathroom" },
  { title: "Kitchen", value: "Kitchen" },
  { title: "Living Room", value: "Living Room" },
  { title: "Outbuilding", value: "Outbuilding" },
  { title: "Other", value: "Other" },
];

const usCanadaBoilerLocationOptions = [
  { title: "Basement/Cellar", value: "Basement/Cellar" },
  { title: "Utility Room", value: "Utility Room" },
  { title: "Garage", value: "Garage" },
  { title: "Mechanical Room", value: "Mechanical Room" },
  { title: "Closet", value: "Closet" },
  { title: "Detached Structure", value: "Detached Structure" },
  { title: "Attic", value: "Attic" },
  { title: "Crawl Space", value: "Crawl Space" },
  { title: "Warehouse/Shop", value: "Warehouse/Shop" },
  { title: "Other", value: "Other" },
];

export const boilerLocationOptions = {
  [LOCALE_UK]: ukBoilerLocationOptions,
  [LOCALE_IE]: ukBoilerLocationOptions,
  [LOCALE_CA]: usCanadaBoilerLocationOptions,
  [LOCALE_US]: usCanadaBoilerLocationOptions,
};

export const laCodeOptions = [
  { value: "E06000001", title: "Hartlepool Borough Council" },
  { value: "E06000002", title: "Middlesbrough Borough Council" },
  { value: "E06000003", title: "Redcar & Cleveland Borough Council" },
  { value: "E06000004", title: "Stockton on Tees Borough Council" },
  { value: "E06000005", title: "Darlington Borough Council" },
  { value: "E06000006", title: "Halton Borough Council" },
  { value: "E06000007", title: "Warrington Borough Council" },
  { value: "E06000008", title: "Blackburn with Darwen Borough Council" },
  { value: "E06000009", title: "Blackpool Borough Council" },
  { value: "E06000010", title: "Kingston Upon Hull City Council" },
  { value: "E06000011", title: "East Riding of Yorkshire Council" },
  { value: "E06000012", title: "North East Lincolnshire Council" },
  { value: "E06000013", title: "North Lincolnshire Council" },
  { value: "E06000014", title: "York Council, City of" },
  { value: "E06000015", title: "Derby City Council" },
  { value: "E06000016", title: "Leicester City Council" },
  { value: "E06000017", title: "Rutland County Council" },
  { value: "E06000018", title: "Nottingham City Council" },
  { value: "E06000019", title: "Herefordshire Council" },
  { value: "E06000020", title: "Telford & Wrekin Council" },
  { value: "E06000021", title: "Stoke on Trent City Council" },
  { value: "E06000022", title: "Bath & North East Somerset Council" },
  { value: "E06000023", title: "Bristol City Council" },
  { value: "E06000024", title: "North Somerset Council" },
  { value: "E06000025", title: "South Gloucestershire Council" },
  { value: "E06000026", title: "Plymouth City Council" },
  { value: "E06000027", title: "Torbay Council" },
  { value: "E06000028", title: "Bournemouth Borough Council" },
  { value: "E06000029", title: "Poole, Borough of" },
  { value: "E06000030", title: "Swindon Borough Council" },
  { value: "E06000031", title: "Peterborough City Council" },
  { value: "E06000032", title: "Luton Borough Council" },
  { value: "E06000033", title: "Southend on Sea Borough Council" },
  { value: "E06000034", title: "Thurrock Council" },
  { value: "E06000035", title: "Medway Council" },
  { value: "E06000036", title: "Bracknell Forest Borough Council" },
  { value: "E06000037", title: "West Berkshire District Council" },
  { value: "E06000038", title: "Reading Borough Council" },
  { value: "E06000039", title: "Slough Borough Council" },
  { value: "E06000040", title: "Windsor & Maidenhead, Royal Borough of" },
  { value: "E06000041", title: "Wokingham District Council" },
  { value: "E06000042", title: "Milton Keynes Council" },
  { value: "E06000043", title: "Brighton and Hove City Council" },
  { value: "E06000044", title: "Portsmouth City Council" },
  { value: "E06000045", title: "Southampton City Council" },
  { value: "E06000046", title: "Isle of Wight Council" },
  { value: "E06000047", title: "Durham Unitary Authority" },
  { value: "E06000049", title: "Cheshire East Unitary Authority" },
  { value: "E06000050", title: "Cheshire West and Chester Unitary Authority" },
  { value: "E06000051", title: "Shropshire Unitary Authority" },
  { value: "E06000052", title: "Cornwall Unitary Authority" },
  { value: "E06000053", title: "The Scilly Isles Council" },
  { value: "E06000054", title: "Wiltshire Unitary Authority" },
  { value: "E06000055", title: "Bedford Unitary Authority" },
  { value: "E06000056", title: "Central Bedfordshire Unitary Authority " },
  { value: "E06000057", title: "Northumberland Unitary Authority" },
  { value: "E06000058", title: "Bournemouth, Christchurch and Poole" },
  { value: "E06000059", title: "Dorset" },
  { value: "E06000060", title: "Buckinghamshire Unitary Authority" },
  { value: "E06000061", title: "North Northamptonshire" },
  { value: "E06000062", title: "West Northamptonshire" },
  { value: "E06000063", title: "Cumberland" },
  { value: "E06000064", title: "Westmorland and Furness" },
  { value: "E06000065", title: "North Yorkshire" },
  { value: "E06000066", title: "Somerset" },
  { value: "E07000008", title: "Cambridge City Council" },
  { value: "E07000009", title: "East Cambridgeshire District Council" },
  { value: "E07000010", title: "Fenland District Council" },
  { value: "E07000011", title: "Huntingdonshire District Council" },
  { value: "E07000012", title: "South Cambridgeshire District Council" },
  { value: "E07000032", title: "Amber Valley Borough Council" },
  { value: "E07000033", title: "Bolsover, District of" },
  { value: "E07000034", title: "Chesterfield Borough Council" },
  { value: "E07000035", title: "Derbyshire Dales District Council" },
  { value: "E07000036", title: "Erewash Borough Council" },
  { value: "E07000037", title: "High Peak Borough Council" },
  { value: "E07000038", title: "North East Derbyshire District Council" },
  { value: "E07000039", title: "South Derbyshire District Council" },
  { value: "E07000040", title: "East Devon District Council" },
  { value: "E07000041", title: "Exeter City Council" },
  { value: "E07000042", title: "Mid Devon District Council" },
  { value: "E07000043", title: "North Devon District Council" },
  { value: "E07000044", title: "South Hams District Council" },
  { value: "E07000045", title: "Teignbridge District Council" },
  { value: "E07000046", title: "Torridge District Council" },
  { value: "E07000047", title: "West Devon District Council" },
  { value: "E07000061", title: "Eastbourne Borough Council" },
  { value: "E07000062", title: "Hastings Borough Council" },
  { value: "E07000063", title: "Lewes District Council" },
  { value: "E07000064", title: "Rother District Council" },
  { value: "E07000065", title: "Wealden District Council" },
  { value: "E07000066", title: "Basildon District Council" },
  { value: "E07000067", title: "Braintree District Council" },
  { value: "E07000068", title: "Brentwood Borough Council" },
  { value: "E07000069", title: "Castle Point Borough Council" },
  { value: "E07000070", title: "Chelmsford Borough Council" },
  { value: "E07000071", title: "Colchester Borough Council" },
  { value: "E07000072", title: "Epping Forest District Council" },
  { value: "E07000073", title: "Harlow District Council" },
  { value: "E07000074", title: "Maldon District Council" },
  { value: "E07000075", title: "Rochford District Council" },
  { value: "E07000076", title: "Tendring District Council" },
  { value: "E07000077", title: "Uttlesford District Council" },
  { value: "E07000078", title: "Cheltenham  Borough  Council" },
  { value: "E07000079", title: "Cotswold District Council" },
  { value: "E07000080", title: "Forest of Dean District Council" },
  { value: "E07000081", title: "Gloucester  City  Council" },
  { value: "E07000082", title: "Stroud District Council" },
  { value: "E07000083", title: "Tewkesbury Borough Council" },
  { value: "E07000084", title: "Basingstoke & Deane Borough Council" },
  { value: "E07000085", title: "East Hampshire District Council" },
  { value: "E07000086", title: "Eastleigh Borough Council" },
  {
    value: "E07000087",
    title: "Building Control Partnership c/o Gosport & Fareham Borough Council",
  },
  {
    value: "E07000088",
    title: "Building Control Partnership c/o Gosport & Fareham Borough Council",
  },
  { value: "E07000089", title: "Hart District Council" },
  { value: "E07000090", title: "Havant Borough Council" },
  { value: "E07000091", title: "New Forest District Council" },
  { value: "E07000092", title: "Rushmoor Borough Council" },
  { value: "E07000093", title: "Test Valley Borough Council" },
  { value: "E07000094", title: "Winchester City Council" },
  { value: "E07000095", title: "Broxbourne Borough Council" },
  { value: "E07000096", title: "Dacorum Borough Council" },
  { value: "E07000098", title: "Hertsmere Borough Council" },
  { value: "E07000099", title: "North Hertfordshire District Council" },
  { value: "E07000102", title: "Three Rivers District Council" },
  { value: "E07000103", title: "Watford Council" },
  { value: "E07000105", title: "Ashford Borough Council" },
  { value: "E07000106", title: "Canterbury City Council" },
  { value: "E07000107", title: "Dartford Borough Council" },
  { value: "E07000108", title: "Dover District Council" },
  { value: "E07000109", title: "South Thames Gateway Building Control (STG)" },
  { value: "E07000110", title: "Maidstone Borough Council" },
  { value: "E07000111", title: "Sevenoaks District Council" },
  { value: "E07000112", title: "Folkestone and Hythe District Council" },
  { value: "E07000113", title: "Swale Borough Council" },
  { value: "E07000114", title: "Thanet District Council" },
  { value: "E07000115", title: "Tonbridge & Malling  Borough  Council" },
  { value: "E07000116", title: "Tunbridge Wells Borough Council" },
  { value: "E07000117", title: "Burnley Borough Council" },
  { value: "E07000118", title: "Chorley Borough Council" },
  { value: "E07000119", title: "Fylde Borough Council" },
  { value: "E07000120", title: "Hyndburn Borough Council" },
  { value: "E07000121", title: "Lancaster City Council" },
  { value: "E07000122", title: "Pendle, Borough of" },
  { value: "E07000123", title: "Preston City Council" },
  { value: "E07000124", title: "Ribble Valley Borough Council" },
  { value: "E07000125", title: "Rossendale Borough Council" },
  { value: "E07000126", title: "South Ribble Borough Council" },
  { value: "E07000127", title: "West Lancashire District Council" },
  { value: "E07000128", title: "Wyre Borough Council" },
  { value: "E07000129", title: "Blaby District Council" },
  { value: "E07000130", title: "Charnwood Borough Council" },
  { value: "E07000131", title: "Harborough District Council" },
  { value: "E07000132", title: "Hinckley & Bosworth Borough Council" },
  { value: "E07000133", title: "Melton Borough Council" },
  { value: "E07000134", title: "North West Leicestershire District Council" },
  { value: "E07000135", title: "Oadby & Wigston Borough Council" },
  { value: "E07000136", title: "Boston Borough Council" },
  { value: "E07000137", title: "East Lindsey District Council" },
  { value: "E07000138", title: "Lincoln, City of" },
  { value: "E07000139", title: "North Kesteven District Council" },
  { value: "E07000140", title: "South Holland District Council" },
  { value: "E07000141", title: "South Kesteven District Council" },
  { value: "E07000142", title: "West Lindsey District Council" },
  { value: "E07000143", title: "Breckland Council" },
  { value: "E07000144", title: "Broadland - CNC Building Control Consultancy" },
  { value: "E07000145", title: "Great Yarmouth Borough Council" },
  { value: "E07000146", title: "King's Lynn & West Norfolk Borough Council" },
  { value: "E07000147", title: "North Norfolk District Council" },
  { value: "E07000148", title: "Norwich - CNC Building Control Consultancy" },
  {
    value: "E07000149",
    title: "South Norfolk - CNC Building Control Consultancy",
  },
  { value: "E07000170", title: "Ashfield District Council" },
  { value: "E07000171", title: "Bassetlaw District Council" },
  { value: "E07000172", title: "Broxtowe District Council" },
  { value: "E07000173", title: "Gedling Borough Council" },
  { value: "E07000174", title: "Mansfield District Council" },
  { value: "E07000175", title: "Newark & Sherwood District Council" },
  { value: "E07000176", title: "Rushcliffe Borough Council" },
  { value: "E07000177", title: "Cherwell District Council" },
  { value: "E07000178", title: "Oxford City Council" },
  { value: "E07000179", title: "South Oxfordshire District Council" },
  { value: "E07000180", title: "Vale of White Horse District Council" },
  { value: "E07000181", title: "West Oxfordshire District Council" },
  { value: "E07000192", title: "Cannock Chase Council" },
  { value: "E07000193", title: "East Staffordshire Borough Council" },
  { value: "E07000194", title: "Lichfield  District Council" },
  { value: "E07000195", title: "Newcastle under Lyme" },
  { value: "E07000196", title: "South Staffordshire Council" },
  { value: "E07000197", title: "Stafford Borough Council" },
  { value: "E07000198", title: "Staffordshire Moorlands District Council" },
  { value: "E07000199", title: "Tamworth Borough Council" },
  { value: "E07000200", title: "Babergh District Council" },
  { value: "E07000201", title: "Forest Heath District Council" },
  { value: "E07000202", title: "Ipswich Borough Council" },
  { value: "E07000203", title: "Mid Suffolk District Council" },
  { value: "E07000207", title: "Elmbridge Borough Council" },
  { value: "E07000208", title: "Epsom & Ewell Borough Council" },
  { value: "E07000209", title: "Guildford Borough Council" },
  { value: "E07000210", title: "Mole Valley District Council" },
  { value: "E07000211", title: "Reigate & Banstead Borough Council" },
  { value: "E07000212", title: "Runnymede Borough Council" },
  { value: "E07000213", title: "Spelthorne, Borough of" },
  { value: "E07000214", title: "Surrey Heath Borough Council" },
  { value: "E07000215", title: "Tandridge District Council" },
  { value: "E07000216", title: "Waverley Borough Council" },
  { value: "E07000217", title: "Woking Borough Council" },
  { value: "E07000218", title: "North Warwickshire Borough Council" },
  { value: "E07000219", title: "Nuneaton & Bedworth Borough Council" },
  { value: "E07000220", title: "Rugby Borough Council" },
  { value: "E07000221", title: "Stratford Upon Avon District Council" },
  { value: "E07000222", title: "Warwick District Council" },
  { value: "E07000223", title: "Adur District Council" },
  { value: "E07000224", title: "Arun District Council" },
  { value: "E07000225", title: "Chichester District Council" },
  { value: "E07000226", title: "Crawley Borough Council" },
  { value: "E07000227", title: "Horsham District Council" },
  { value: "E07000228", title: "Mid Sussex District Council" },
  { value: "E07000229", title: "Worthing Borough Council" },
  { value: "E07000234", title: "Bromsgrove District Council" },
  { value: "E07000235", title: "Malvern Hills District  Council" },
  { value: "E07000236", title: "Redditch Borough Council" },
  { value: "E07000237", title: "Worcester City Council" },
  { value: "E07000238", title: "Wychavon District Council" },
  { value: "E07000239", title: "Wyre Forest District Council" },
  { value: "E07000240", title: "St Albans District Council" },
  { value: "E07000241", title: "Welwyn Hatfield District Council" },
  { value: "E07000242", title: "East Hertfordshire District Council" },
  { value: "E07000243", title: "Stevenage Borough Council" },
  { value: "E07000244", title: "East Suffolk" },
  { value: "E07000245", title: "West Suffolk" },
  { value: "E08000001", title: "Bolton Metropolitan Borough Council" },
  { value: "E08000002", title: "Bury, Metropolitan Borough of" },
  { value: "E08000003", title: "Manchester City Council" },
  { value: "E08000004", title: "Oldham Metropolitan Borough Council" },
  { value: "E08000005", title: "Rochdale Metropolitan Borough Council" },
  { value: "E08000006", title: "Salford City Council" },
  { value: "E08000007", title: "Stockport Metropolitan Borough Council" },
  { value: "E08000008", title: "Tameside Metropolitan Borough Council" },
  { value: "E08000009", title: "Trafford Metropolitan Borough Council" },
  { value: "E08000010", title: "Wigan Council" },
  { value: "E08000011", title: "Knowsley Metropolitan Borough Council" },
  { value: "E08000012", title: "Liverpool City Council" },
  { value: "E08000013", title: "St Helens Council" },
  { value: "E08000014", title: "Sefton Metropolitan Borough Council" },
  { value: "E08000015", title: "Wirral, Metropolitan Borough of" },
  { value: "E08000016", title: "Barnsley Metropolitan Borough Council" },
  { value: "E08000017", title: "Doncaster Metropolitan Borough Council" },
  { value: "E08000018", title: "Rotherham Metropolitan Borough Council" },
  { value: "E08000019", title: "Sheffield City Council" },
  { value: "E08000021", title: "Newcastle upon Tyne" },
  { value: "E08000022", title: "North Tyneside Council" },
  { value: "E08000023", title: "South Tyneside Council" },
  { value: "E08000024", title: "Sunderland, City of" },
  { value: "E08000025", title: "Birmingham City Council" },
  { value: "E08000026", title: "Coventry City Council" },
  { value: "E08000027", title: "Dudley Metropolitan Borough Council" },
  { value: "E08000028", title: "Sandwell Metropolitan Borough Council" },
  { value: "E08000029", title: "Solihull Metropolitan Borough  Council" },
  { value: "E08000030", title: "Walsall Metropolitan Borough Council" },
  { value: "E08000031", title: "Wolverhampton Metropolitan Borough Council" },
  { value: "E08000032", title: "Bradford Metropolitan District Council" },
  { value: "E08000033", title: "Calderdale Metropolitan Borough Council" },
  { value: "E08000034", title: "Kirklees Metropolitan Council" },
  { value: "E08000035", title: "Leeds City Council" },
  { value: "E08000036", title: "Wakefield Metropolitan District Council" },
  { value: "E08000037", title: "Gateshead Council" },
  { value: "E09000001", title: "London, Corporation of" },
  { value: "E09000002", title: "Barking & Dagenham, London Borough of" },
  { value: "E09000003", title: "Barnet, London Borough of" },
  { value: "E09000004", title: "Bexley, London Borough of" },
  { value: "E09000005", title: "Brent, London Borough of" },
  { value: "E09000006", title: "Bromley, London Borough of" },
  { value: "E09000007", title: "Camden, London Borough of" },
  { value: "E09000008", title: "Croydon, London Borough of" },
  { value: "E09000009", title: "Ealing,  London Borough of" },
  { value: "E09000010", title: "Enfield, London Borough of" },
  { value: "E09000011", title: "Greenwich, London Borough of" },
  { value: "E09000012", title: "Hackney, London Borough of" },
  { value: "E09000013", title: "Hammersmith & Fulham, London Borough of" },
  { value: "E09000014", title: "Haringey, London Borough of" },
  { value: "E09000015", title: "Harrow, London Borough of" },
  { value: "E09000016", title: "Havering, London Borough of" },
  { value: "E09000017", title: "Hillingdon, London Borough of" },
  { value: "E09000018", title: "Hounslow, London Borough of" },
  { value: "E09000019", title: "Islington, London Borough of" },
  { value: "E09000020", title: "Kensington & Chelsea, Royal Borough of" },
  { value: "E09000021", title: "Kingston upon Thames, Royal Borough of" },
  { value: "E09000022", title: "Lambeth, London Borough of" },
  { value: "E09000023", title: "Lewisham, London Borough of" },
  { value: "E09000024", title: "Merton, London Borough of" },
  { value: "E09000025", title: "Newham, London Borough of" },
  { value: "E09000026", title: "Redbridge, London Borough of" },
  { value: "E09000027", title: "Richmond upon Thames, London Borough of" },
  { value: "E09000028", title: "Southwark, London Borough of" },
  { value: "E09000029", title: "Sutton, London Borough of" },
  { value: "E09000030", title: "Tower Hamlets, London Borough of" },
  { value: "E09000031", title: "Waltham Forest, London Borough of" },
  { value: "E09000032", title: "Wandsworth, London Borough of" },
  { value: "E09000033", title: "Westminster,  London Borough of" },
  { value: "GUER", title: "Guernsey" },
  { value: "IM01", title: "Isle Of Man" },
  { value: "JE01", title: "Jersey" },
  { value: "N09000001", title: "Antrim an Newtonabbey" },
  { value: "N09000002", title: "Armagh, Banbridge and Craigavon" },
  { value: "N09000003", title: "Belfast" },
  { value: "N09000004", title: "Causeway Coast and Glens" },
  { value: "N09000005", title: "Derry and Strabane" },
  { value: "N09000006", title: "Fermanagh and Omagh" },
  { value: "N09000007", title: "Lisburn and Castlereagh" },
  { value: "N09000008", title: "Mid and East Antrim" },
  { value: "N09000009", title: "Mid Ulster" },
  { value: "N09000010", title: "Newry, Mourne and Down" },
  { value: "N09000011", title: "North Down and Ards" },
  { value: "S12000005", title: "Clackmannanshire" },
  { value: "S12000006", title: "Dumfries & Galloway" },
  { value: "S12000008", title: "East Ayrshire" },
  { value: "S12000010", title: "East Lothian" },
  { value: "S12000011", title: "East Renfrewshire" },
  { value: "S12000013", title: "Eilean Siar" },
  { value: "S12000014", title: "Falkirk" },
  { value: "S12000017", title: "Highland" },
  { value: "S12000018", title: "Inverclyde" },
  { value: "S12000019", title: "Midlothian" },
  { value: "S12000020", title: "Moray" },
  { value: "S12000021", title: "North Ayrshire" },
  { value: "S12000023", title: "Orkney Islands" },
  { value: "S12000026", title: "Scottish Borders" },
  { value: "S12000027", title: "Shetland Islands" },
  { value: "S12000028", title: "South Ayrshire" },
  { value: "S12000029", title: "South Lanarkshire" },
  { value: "S12000030", title: "Stirling" },
  { value: "S12000033", title: "Aberdeen City" },
  { value: "S12000034", title: "Aberdeenshire" },
  { value: "S12000035", title: "Argyll & Bute" },
  { value: "S12000036", title: "Edinburgh, City of" },
  { value: "S12000038", title: "Renfrewshire" },
  { value: "S12000039", title: "West Dunbartonshire" },
  { value: "S12000040", title: "West Lothian" },
  { value: "S12000041", title: "Angus" },
  { value: "S12000042", title: "Dundee City" },
  { value: "S12000045", title: "East Dunbartonshire" },
  { value: "S12000047", title: "Fife Council" },
  { value: "S12000048", title: "Perth and Kinross Council" },
  { value: "S12000049", title: "Glasgow City" },
  { value: "S12000050", title: "North Lanarkshire" },
  { value: "W06000001", title: "Isle of Anglesey County Council" },
  { value: "W06000002", title: "Gwynedd Council" },
  { value: "W06000003", title: "Conwy County Borough Council" },
  { value: "W06000004", title: "Denbighshire County Council" },
  { value: "W06000005", title: "Flintshire County Council" },
  { value: "W06000006", title: "Wrexham County Borough Council" },
  { value: "W06000008", title: "Ceredigion County Council" },
  { value: "W06000009", title: "Pembrokeshire County Council" },
  { value: "W06000010", title: "Carmarthenshire County Council" },
  { value: "W06000011", title: "Swansea, City & County of" },
  { value: "W06000012", title: "Neath Port Talbot County Borough Council" },
  { value: "W06000013", title: "Bridgend County Borough Council" },
  { value: "W06000014", title: "Vale of Glamorgan Council" },
  { value: "W06000015", title: "Cardiff County Council" },
  { value: "W06000016", title: "Rhondda-Cynon-Taf County Borough Council" },
  { value: "W06000018", title: "Caerphilly County Borough Council" },
  { value: "W06000019", title: "Blaenau Gwent County Borough Council" },
  { value: "W06000020", title: "Torfaen County Borough Council" },
  { value: "W06000021", title: "Monmouthshire County Council" },
  { value: "W06000022", title: "Newport City Council" },
  { value: "W06000023", title: "Powys County Council" },
  { value: "W06000024", title: "Merthyr Tydfil County Borough Council" },
  { value: "notListed", title: "The address is not listed" },
];

export const mobileNumberRegExp = /^((\+(44|353)(\s)?)|0)7\d{3}(\s)?\d{6}/;

export const CONTENTFUL_REGIONS = {
  [COUNTRY_CODE_UK]: "Available in GB + NI",
  [COUNTRY_CODE_IE]: "Available in ROI",
  [COUNTRY_CODE_US]: "Available in US",
  [COUNTRY_CODE_CA]: "Available in CA",
};

export const CONTENTFUL_LOCALE_REGIONS = {
  [LOCALE_UK]: "Available in GB + NI",
  [LOCALE_IE]: "Available in ROI",
  [LOCALE_US]: "Available in US",
  [LOCALE_CA]: "Available in CA",
};

export const bandColours = {
  bronze: "#9F7330",
  silver: "#A6A9AA",
  gold: "#DAA520",
};

export const postcodeRegExp =
  /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? [0-9][A-Za-z]{2}|[Gg][Ii][Rr] 0[Aa]{2})$/;

export const irishPostcodeRegExp =
  /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/;

export const usPostcodeRegExp = /^\d{5}(?:[-\s]\d{4})?$/;

export const canadaPostcodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

export const websiteRegExp =
  /^((ftp|http|https):\/\/)?(www\.)?(?!\.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[a-zA-Z0-9_#-]+)*(\/[a-zA-Z0-9_-]+\\?[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+(\\&[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+)*)?/;

export const countryCodeOptions = [
  { title: "United Kingdom", value: "GB", image: UnitedKingdom },
  { title: "Republic of Ireland", value: "IE", image: Ireland },
];

if (process.env.GATSBY_FEATURE_US_CAN_ENABLED === "true") {
  countryCodeOptions.push(
    { title: "United States", value: "US", image: UnitedStates },
    { title: "Canada", value: "CA", image: Canada }
  );
}

export const usRegionOptions = [
  { title: "Alabama", value: "AL" },
  { title: "Alaska", value: "AK" },
  { title: "Arizona", value: "AZ" },
  { title: "Arkansas", value: "AR" },
  { title: "California", value: "CA" },
  { title: "Colorado", value: "CO" },
  { title: "Connecticut", value: "CT" },
  { title: "Delaware", value: "DE" },
  { title: "District Of Columbia", value: "DC" },
  { title: "Florida", value: "FL" },
  { title: "Georgia", value: "GA" },
  { title: "Hawaii", value: "HI" },
  { title: "Idaho", value: "ID" },
  { title: "Illinois", value: "IL" },
  { title: "Indiana", value: "IN" },
  { title: "Iowa", value: "IA" },
  { title: "Kansas", value: "KS" },
  { title: "Kentucky", value: "KY" },
  { title: "Louisiana", value: "LA" },
  { title: "Maine", value: "ME" },
  { title: "Maryland", value: "MD" },
  { title: "Massachusetts", value: "MA" },
  { title: "Michigan", value: "MI" },
  { title: "Minnesota", value: "MN" },
  { title: "Mississippi", value: "MS" },
  { title: "Missouri", value: "MO" },
  { title: "Montana", value: "MT" },
  { title: "Nebraska", value: "NE" },
  { title: "Nevada", value: "NV" },
  { title: "New Hampshire", value: "NH" },
  { title: "New Jersey", value: "NJ" },
  { title: "New Mexico", value: "NM" },
  { title: "New York", value: "NY" },
  { title: "North Carolina", value: "NC" },
  { title: "North Dakota", value: "ND" },
  { title: "Ohio", value: "OH" },
  { title: "Oklahoma", value: "OK" },
  { title: "Oregon", value: "OR" },
  { title: "Pennsylvania", value: "PA" },
  { title: "Rhode Island", value: "RI" },
  { title: "South Carolina", value: "SC" },
  { title: "South Dakota", value: "SD" },
  { title: "Tennessee", value: "TN" },
  { title: "Texas", value: "TX" },
  { title: "Utah", value: "UT" },
  { title: "Vermont", value: "VT" },
  { title: "Virginia", value: "VA" },
  { title: "Washington", value: "WA" },
  { title: "West Virginia", value: "WV" },
  { title: "Wisconsin", value: "WI" },
  { title: "Wyoming", value: "WY" },
];

const canadaRegionOptions = [
  { title: "Alberta", value: "AB" },
  { title: "British Columbia", value: "BC" },
  { title: "Manitoba", value: "MB" },
  { title: "New Brunswick", value: "NB" },
  { title: "Newfoundland and Labrador", value: "NL" },
  { title: "Nova Scotia", value: "NS" },
  { title: "Northwest Territories", value: "NT" },
  { title: "Nunavut", value: "NU" },
  { title: "Ontario", value: "ON" },
  { title: "Prince Edward Island", value: "PEI" },
  { title: "Quebec", value: "QC" },
  { title: "Saskatchewan", value: "SK" },
  { title: "Yukon", value: "YT" },
];

export const ASSET_REGISTRATION_INPUT_FIELDS = [
  "id",
  "installerName",
  "submissionAssetType",
  "gasSafe",
  "assetType",
  "gasType",
  "assetInfo.applianceId",
  "assetInfo.applianceModelId",
  "assetInfo.currentWarrantyLength",
  "assetInfo.hasFilterCheck",
  "assetInfo.imageUrl",
  "assetInfo.isRegistered",
  "assetInfo.modelName",
  "assetInfo.friendlyName",
  "assetInfo.salesforceId",
  "assetInfo.sapNumber",
  "assetInfo.serialNumber",
  "assetInfo.version",
  "assetInfo.warrantyDurationUnit",
  "controlAssetInfo.applianceId",
  "controlAssetInfo.applianceModelId",
  "controlAssetInfo.currentWarrantyLength",
  "controlAssetInfo.hasFilterCheck",
  "controlAssetInfo.imageUrl",
  "controlAssetInfo.isRegistered",
  "controlAssetInfo.modelName",
  "controlAssetInfo.friendlyName",
  "controlAssetInfo.salesforceId",
  "controlAssetInfo.sapNumber",
  "controlAssetInfo.serialNumber",
  "controlAssetInfo.version",
  "controlAssetInfo.warrantyDurationUnit",
  "cylinderAssetInfo.applianceId",
  "cylinderAssetInfo.applianceModelId",
  "cylinderAssetInfo.currentWarrantyLength",
  "cylinderAssetInfo.hasFilterCheck",
  "cylinderAssetInfo.imageUrl",
  "cylinderAssetInfo.isRegistered",
  "cylinderAssetInfo.modelName",
  "cylinderAssetInfo.friendlyName",
  "cylinderAssetInfo.salesforceId",
  "cylinderAssetInfo.sapNumber",
  "cylinderAssetInfo.serialNumber",
  "cylinderAssetInfo.version",
  "cylinderAssetInfo.warrantyDurationUnit",
  "filterAssetInfo.applianceId",
  "filterAssetInfo.applianceModelId",
  "filterAssetInfo.currentWarrantyLength",
  "filterAssetInfo.hasFilterCheck",
  "filterAssetInfo.imageUrl",
  "filterAssetInfo.isRegistered",
  "filterAssetInfo.modelName",
  "filterAssetInfo.friendlyName",
  "filterAssetInfo.salesforceId",
  "filterAssetInfo.sapNumber",
  "filterAssetInfo.serialNumber",
  "filterAssetInfo.version",
  "filterAssetInfo.warrantyDurationUnit",
  "customerDetails.customerConsent",
  "customerDetails.customerDetailsKnown",
  "customerDetails.email",
  "customerDetails.firstName",
  "customerDetails.lastName",
  "customerDetails.newBuiltHome",
  "customerDetails.telephone",
  "customerDetails.title",
  "customerDetails.controllerToControllerAgreement",
  "engineerDetails.businessRegistrationNumber",
  "engineerDetails.name",
  "engineerDetails.engineerId",
  "installationDetails.address",
  "installationDetails.boilerLocation",
  "installationDetails.city",
  "installationDetails.installationDate",
  "installationDetails.houseNumber",
  "installationDetails.laCode",
  "installationDetails.postcode",
  "installationDetails.region",
  "installationDetails.propertyType",
  "installationDetails.street",
  "installationDetails.latitude",
  "installationDetails.longitude",
  "gasSafeEmail",
  "gasSafeAlternateAddress.address",
  "gasSafeAlternateAddress.city",
  "gasSafeAlternateAddress.firstName",
  "gasSafeAlternateAddress.houseNumber",
  "gasSafeAlternateAddress.lastName",
  "gasSafeAlternateAddress.postcode",
  "gasSafeAlternateAddress.street",
  "gasSafeAlternateAddress.title",
  "lpgInfo.applianceId",
  "lpgInfo.applianceModelId",
  "lpgInfo.currentWarrantyLength",
  "lpgInfo.hasFilterCheck",
  "lpgInfo.imageUrl",
  "lpgInfo.isRegistered",
  "lpgInfo.modelName",
  "lpgInfo.friendlyName",
  "lpgInfo.salesforceId",
  "lpgInfo.sapNumber",
  "lpgInfo.serialNumber",
  "lpgInfo.version",
  "lpgInfo.warrantyDurationUnit",
  "merchantDetails.merchant",
  "merchantDetails.merchantLocation",
  "extendedWarranty.apply",
  "extendedWarranty.coHigh",
  "extendedWarranty.coLow",
  "extendedWarranty.co2High",
  "extendedWarranty.co2Low",
  "extendedWarranty.o2High",
  "extendedWarranty.o2Low",
  "extendedWarranty.durationInMonths",
  "extendedWarranty.images",
];

export const STATUS_ENROLLED = "enrolled";
export const STATUS_QUALIFIED = "qualified";
export const STATUS_COMING_SOON = "coming soon";
export const STATUS_NOT_ENROLLED = "not enrolled";
export const STATUS_QUALIFICATION_CLOSED = "qualification closed";
