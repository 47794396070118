import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { get, omit } from "lodash";
import * as authService from "../services/auth";
import { useApp, useGraphql } from "../hooks";
import { getMyInstallerAccountQuery } from "../api/queries";
import { dataLayerPush, gaHandleSessionLoad } from "support/analytics";
import { LocaleContext } from "./LocaleContext";
import { determineLocaleFromCountry } from "support/constants";
import * as Sentry from "@sentry/gatsby";

export const AuthContext = createContext({
  isAuthenticated: false,
  isLoggedIn: false,
  user: {},
  userEmail: "",
  loadingSession: true,
  login: async () => {},
  logout: async () => {},
  verifyAccount: async () => {},
  forgotPassword: async () => {},
  forgotPasswordSubmit: async () => {},
  changePassword: async () => {},
  requestChangeEmail: async () => {},
  verifyChangeEmail: async () => {},
  refreshUserEmail: async () => {},
  refreshUserState: async () => {},
  isMaxAi: false,
});

class TimeOutError extends Error {
  constructor(message) {
    super(message);
    this.name = "TimeOutError";
  }
}

// When viewing a Gatsby Cloud preview a marketing user needs to be able
// to view content changes without being authenticated. This mocked user
// should never be able to interact with the API.
const GATSBY_PREVIEW_USER = {
  isApproved: true,
  waitingForApproval: false,
  firstName: "Preview",
  lastName: "User",
  username: "Preview User",
  email: "preview.user@groupe-atlantic.co.uk",
  cognitoId: "abc-123",
  savedRegistrationCount: 0,
  failedRegistrations: [],
  lastSeenMaxAIEligible: "2023-01-04T14:08:04.798Z",
  id: "abc-123",
  gasSafeNumber: "193110",
  cpsName: "TEST",
  points: 5928,
  band: "gold",
  name: "Preview User Co",
  houseNumber: "1",
  street1: "National Avenue",
  city: "HULL",
  country: "GB",
  countryCode: "GB",
  postCode: "HU5 4JB",
  businessEmail: "preview.user@groupe-atlantic.co.uk",
  phone: "01482492251",
  website: null,
  furtherInformation: null,
  contacts: [
    {
      email: "preview.user@groupe-atlantic.co.uk",
      firstName: "Preview",
      id: "def-456",
      lastName: "User",
      phone: "01482492251",
      mobilePhone: "071234567890",
      installerConnectEmail: "preview.user@groupe-atlantic.co.uk",
    },
  ],
  maxAiStatus: "Accredited",
  platinumInstaller: false,
  termsConditionsAccepted: true,
  doNotEmail: true,
  maxAiRenewalDate: null,
  maxAiEligibilityPoints: 0,
  level: "Warm",
};

export const AuthProvider = ({ children }) => {
  const [loadingSession, setLoadingSession] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const { setLocale } = useContext(LocaleContext);
  const { addMessage } = useApp();

  const { executeQuery: executeGetMyInstallerAccountQuery } = useGraphql(
    getMyInstallerAccountQuery
  );

  const getInstallerAccount = useCallback(async () => {
    const installerAccount = await executeGetMyInstallerAccountQuery();

    if (installerAccount.errors && installerAccount.errors.length > 0) {
      const errorMessage =
        "Something went wrong, if this issue persists please reach out to customer support";
      addMessage("error", errorMessage);

      throw new TimeOutError(errorMessage);
    }

    return {
      ...get(installerAccount, "data.getMyInstallerAccount", {}),
      ...get(installerAccount, "data.getTalonOneProfile", {}),
    };
  }, [executeGetMyInstallerAccountQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateUser = useCallback(async () => {
    const installerAccount = await getInstallerAccount();

    Sentry.setUser({
      id: installerAccount?.cognitoId,
      email: installerAccount?.email,
      band: installerAccount?.account?.band,
    });

    setUser({
      ...omit(installerAccount, ["account"]),
      ...installerAccount.account,
      features: JSON.parse(installerAccount.features),
    });

    const locale = determineLocaleFromCountry(
      installerAccount?.account?.countryCode
    );
    setLocale(locale);
  }, [getInstallerAccount]);

  useEffect(() => {
    const initialiseUserEmail = async () => {
      await refreshUserEmail();
    };
    initialiseUserEmail();
  }, []);

  useEffect(() => {
    if (!loadingSession) {
      gaHandleSessionLoad(user);
    }
  }, [user, loadingSession]);

  useEffect(() => {
    const fetchUserSession = async () => {
      const authUser = await authService.getUser();

      if (authUser) {
        setAuthenticated(true);

        await updateUser(authUser);
      }

      setLoadingSession(false);
    };

    fetchUserSession().catch((e) => {
      console.log(e);
      setAuthenticated(false);
      setLoadingSession(false);
    });
  }, [updateUser]);

  const gatsbyPreviewUserLogin = useCallback(() => {
    setAuthenticated(true);
    setUser(GATSBY_PREVIEW_USER);
  }, []);

  const logout = useCallback(async () => {
    await authService.logout();
    setAuthenticated(false);
    setUser({});
    dataLayerPush({ event: "logout" });
    const locale = determineLocaleFromCountry("");
    setLocale(locale);
    Sentry.setUser(null);
  }, []);

  const gatsbyPreviewUserLogout = useCallback(() => {
    setAuthenticated(false);
    setUser({});
  }, []);

  const forgotPassword = useCallback(async (email) => {
    try {
      await authService.forgotPassword(email);

      return {
        success: true,
      };
    } catch (error) {
      let errorMessage;

      switch (error.code) {
        case "CodeDeliveryFailureException":
          errorMessage =
            "We could not deliver your verification code to the provided email";
          break;
        case "TooManyRequestsException":
          errorMessage =
            "You have exceeded the number of attempts allowed. Please try again shortly";
          break;
        default:
          errorMessage = "An error has occurred. Please try again later";
          break;
      }

      return {
        success: false,
        error: errorMessage,
      };
    }
  }, []);

  const forgotPasswordSubmit = useCallback(async (email, code, newPassword) => {
    try {
      await authService.forgotPasswordSubmit(email, code, newPassword);

      return {
        success: true,
      };
    } catch (error) {
      let errorMessage;

      switch (error.code) {
        case "CodeMismatchException":
          errorMessage = "Verification code not recognised";
          break;
        case "ExpiredCodeException":
          errorMessage =
            "Your verification code has expired. Please resend the code and try again";
          break;
        case "LimitExceededException":
          errorMessage =
            "You have exceeded the number of password reset attempts allowed. Please try again in 15 minutes";
          break;
        case "TooManyFailedAttemptsException":
        case "TooManyRequestsException":
          errorMessage =
            "You have exceeded the number of attempts allowed. Please try again shortly";
          break;
        default:
          errorMessage = "An error has occurred. Please try again later";
          break;
      }

      return {
        success: false,
        error: errorMessage,
      };
    }
  }, []);

  const changePassword = useCallback(async (currentPassword, newPassword) => {
    try {
      await authService.changePassword(currentPassword, newPassword);

      return {
        success: true,
      };
    } catch (error) {
      let errorMessage;

      switch (error.code) {
        case "InvalidPasswordException":
          errorMessage = "The requested password is invalid.";
          break;
        case "LimitExceededException":
        case "TooManyRequestsException":
          errorMessage =
            "You have exceeded the number of attempts allowed. Please try again shortly.";
          break;
        case "UserNotConfirmedException":
          errorMessage = "Your account is not confirmed.";
          break;
        case "PasswordResetRequiredException":
          errorMessage = "A password reset is required.";
          break;
        case "NotAuthorizedException":
          errorMessage = "Current password entered incorrectly";
          break;
        default:
          errorMessage = "An error has occurred. Please try again later";
          break;
      }

      return {
        success: false,
        error: errorMessage,
      };
    }
  }, []);

  const requestChangeEmail = useCallback(async (newEmail) => {
    try {
      await authService.requestChangeEmail(newEmail);

      return {
        success: true,
      };
    } catch (error) {
      let errorMessage = "An error has occurred. Please try again later";

      return {
        success: false,
        error: errorMessage,
      };
    }
  }, []);

  const verifyChangeEmail = useCallback(async (verificationCode) => {
    try {
      await authService.verifyChangeEmail(verificationCode);

      return {
        success: true,
      };
    } catch (error) {
      let errorMessage;

      switch (error.code) {
        case "CodeMismatchException":
          errorMessage = "Verification code not recognised";
          break;
        case "ExpiredCodeException":
          errorMessage =
            "Your verification code has expired. Please resend the code and try again";
          break;
        case "TooManyRequestsException":
          errorMessage =
            "You have exceeded the number of attempts allowed. Please try again shortly";
          break;
        default:
          errorMessage = "An error has occurred. Please try again later";
          break;
      }

      return {
        success: false,
        error: errorMessage,
      };
    }
  }, []);

  const refreshUserEmail = useCallback(async () => {
    try {
      const user = await authService.getUser();
      if (user) {
        const {
          attributes: { email },
        } = user;
        setUserEmail(email);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const verifyAccount = useCallback(async (username, verificationCode) => {
    try {
      await authService.confirmSignUp(username, verificationCode);

      return {
        success: true,
      };
    } catch (error) {
      let errorMessage;

      switch (error.code) {
        case "CodeMismatchException":
          errorMessage = "Verification code not recognised";
          break;
        case "ExpiredCodeException":
          errorMessage =
            "Your verification code has expired. Please resend the code and try again";
          break;
        case "TooManyFailedAttemptsException":
        case "TooManyRequestsException":
          errorMessage =
            "You have exceeded the number of attempts allowed. Please try again shortly";
          break;
        default:
          errorMessage = "An error has occurred. Please try again later";
          break;
      }

      return {
        success: false,
        error: errorMessage,
      };
    }
  }, []);

  const updateUserPoints = useCallback(
    (points) => {
      setUser((user) => ({ ...user, points }));
    },
    [setUser]
  );

  const updateSavedRegistrationCount = useCallback((val) => {
    setUser((user) => ({
      ...user,
      savedRegistrationCount: (user.savedRegistrationCount || 0) + val,
    }));
  });

  const refreshUserState = useCallback(
    async (pendingUserState = {}) => {
      setUser((user) => ({
        ...user,
        ...pendingUserState,
      }));

      try {
        await updateUser();
      } catch (e) {
        console.log(e);
      }
    },
    [updateUser]
  );

  const updateUserBand = useCallback(
    (band) => {
      setUser((user) => ({ ...user, band }));
    },
    [setUser]
  );

  const login = useCallback(
    async (loginUsername, password) => {
      await authService.login(loginUsername, password);

      await updateUser();

      await refreshUserEmail();

      setAuthenticated(true);
      dataLayerPush({ event: "login" });
    },
    [updateUser, refreshUserEmail]
  );

  let providerValue = {
    isAuthenticated: authenticated,
    isLoggedIn: authenticated && !loadingSession,
    user,
    userEmail,
    loadingSession,
    login,
    logout,
    verifyAccount,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    requestChangeEmail,
    verifyChangeEmail,
    refreshUserEmail,
    updateUserPoints,
    refreshUserState,
    updateSavedRegistrationCount,
    updateUserBand,
    isMaxAi: get(user, "maxAiStatus") === "Accredited",
    benchmarkFeature:
      get(user, "features.benchmark") ||
      process.env.GATSBY_FEATURE_BENCHMARK_ENABLED === "true",
  };

  if (process.env.GATSBY_IS_PREVIEW === "true") {
    providerValue = {
      ...providerValue,
      login: gatsbyPreviewUserLogin,
      logout: gatsbyPreviewUserLogout,
    };
  }

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};
