import React, { createContext, useCallback, useEffect, useState } from "react";
import { get, map } from "lodash";
import { getBasketQuery } from "../api/queries";
import {
  addItemToBasketMutation,
  checkoutBasketMutation,
  updateBasketItemMutation,
  removeItemFromBasketMutation,
} from "../api/mutations";
import { useAuth, useGraphql } from "hooks";
import { determineBasePoints } from "support/helpers";

export class BasketError extends Error {
  constructor(errors) {
    super("Failed to update basket");

    this.name = "BasketError";
    this.errors = errors;
  }
}

export const BasketContext = createContext({
  basketItems: [],
  addItem: async () => {},
  removeItem: async () => {},
  availablePoints: 0,
  accountPoints: 0,
});

export const BasketProvider = ({ children }) => {
  const { isLoggedIn, user, isMaxAi, updateUserPoints } = useAuth();
  const [basketItems, setBasketItems] = useState([]);

  const { executeQuery: executeGetBasketQuery } = useGraphql(getBasketQuery);
  const { executeQuery: executeAddItemToBasketMutation } = useGraphql(
    addItemToBasketMutation
  );
  const { executeQuery: executeUpdateBasketItemMutation } = useGraphql(
    updateBasketItemMutation
  );
  const { executeQuery: executeRemoveItemFromBasketMutation } = useGraphql(
    removeItemFromBasketMutation
  );
  const { executeQuery: executeCheckoutBasketMutation } = useGraphql(
    checkoutBasketMutation
  );

  const subtotal = basketItems.reduce((acc, i) => {
    if (isMaxAi && i.pointsRequiredMaxAi !== null) {
      return acc + i.pointsRequiredMaxAi * i.quantity;
    }
    const basePoints = determineBasePoints({
      countryCode: user.countryCode,
      product: {
        pointsRequired: i.pointsRequired,
        pointsRequiredRoi: i.pointsRequiredRoi,
        pointsRequiredUs: i.pointsRequiredUs,
        pointsRequiredCa: i.pointsRequiredCa,
      },
    });

    return acc + basePoints * i.quantity;
  }, 0);

  let accountPoints = 0;

  if (user && typeof user.points === "number") {
    accountPoints = user.points || 0;
  }

  const availablePoints = accountPoints ? accountPoints - subtotal : 0;

  const handleErrors = (e) => {
    if (e.errors) {
      throw new BasketError(map(e.errors, "message"));
    }

    throw e;
  };

  const loadBasket = useCallback(async () => {
    const response = await executeGetBasketQuery({});

    if (response.errors && response.errors.length > 0) {
      handleErrors(response);
    }

    const basketItems = get(response, "data.getBasket.items", []);

    setBasketItems(basketItems);
  }, [executeGetBasketQuery]);

  useEffect(() => {
    if (isLoggedIn && user.isApproved) {
      loadBasket();
    }
  }, [isLoggedIn, loadBasket, user]);

  const addItem = useCallback(
    async (itemId, options, quantity) => {
      const response = await executeAddItemToBasketMutation({
        itemId,
        quantity,
        options,
      });

      if (response.errors && response.errors.length > 0) {
        handleErrors(response);
      }

      loadBasket();
    },
    [executeAddItemToBasketMutation, loadBasket]
  );

  const updateItem = useCallback(
    async (itemId, options, quantity) => {
      const response = await executeUpdateBasketItemMutation({
        itemId,
        quantity,
        options,
      });

      if (response.errors && response.errors.length > 0) {
        handleErrors(response);
      }

      loadBasket();
    },
    [executeUpdateBasketItemMutation, loadBasket]
  );

  const removeItem = useCallback(
    async (itemId, options) => {
      const response = await executeRemoveItemFromBasketMutation({
        itemId,
        options,
      });

      if (response.errors && response.errors.length > 0) {
        handleErrors(response);
      }

      loadBasket();
    },
    [executeRemoveItemFromBasketMutation, loadBasket]
  );

  const checkout = useCallback(async () => {
    const response = await executeCheckoutBasketMutation({});

    if (response.errors && response.errors.length > 0) {
      handleErrors(response);
    }

    updateUserPoints(availablePoints);

    loadBasket();
  }, [
    availablePoints,
    executeCheckoutBasketMutation,
    loadBasket,
    updateUserPoints,
  ]);

  return (
    <BasketContext.Provider
      value={{
        basketItems,
        basketItemCount: basketItems.reduce((acc, i) => acc + i.quantity, 0),
        subtotal,
        availablePoints,
        accountPoints,
        addItem,
        updateItem,
        removeItem,
        checkout,
        loadBasket,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};
