export const addItemToBasketMutation = `
  mutation($itemId: String!, $options: [UserBasketOptionInput], $quantity: Int!) {
    addBasketItem(itemId: $itemId, options: $options, quantity: $quantity)
  }
`;

export const updateBasketItemMutation = `
  mutation($itemId: String!, $options: [UserBasketOptionInput], $quantity: Int!) {
    updateBasketItem(itemId: $itemId, options: $options, quantity: $quantity)
  }
`;

export const removeItemFromBasketMutation = `
  mutation($itemId: String!, $options: [UserBasketOptionInput]) {
    removeBasketItem(itemId: $itemId, options: $options)
  }
`;

export const submitProductRegistrationMutation = `
  mutation($assetRegistration: AssetRegistrationInput) {
    submitProductRegistration(assetRegistration: $assetRegistration) {
      currentBalance
      spentPoints
    }
  }
`;

export const checkoutBasketMutation = `
  mutation {
    checkoutBasket
  }
`;

export const applyToMaxAiMutation = `
mutation {
  applyToMaxAi {
    applicationDate
    assessmentDeadline
    trainingPlatformUrl
    trainingPlatformEnabled
  }
}`;

export const cancelMaxAiMutation = `
mutation {
  cancelMaxAi
}
`;

export const leaveMaxAiSchemeMutation = `
  mutation {
    leaveMaxAiScheme
  }
`;

export const updateMyInstallerAccountMutation = `
  mutation($installerDetails: InstallerDetailsInput) {
    updateMyInstallerAccount(installerDetails: $installerDetails) {
      pendingFields
    }
  }
`;

export const changeLoginEmailMutation = `
  mutation($newEmail: String!) {
    changeLoginEmail(newEmail: $newEmail) {
      newEmail
      success
    }
  }
`;

export const requestCustomReward = `
  mutation($message: String) {
    requestCustomReward(message: $message) {
      orderId
    }
  }
`;

export const emailAssetCertificateMutation = `
  mutation($name: String!, $email: String!, $assetId: String!, $certificateType: CertificateType!) {
    emailAssetCertificate(name: $name, email: $email, assetId: $assetId, certificateType: $certificateType)
  }
`;

export const createCertificateDownloadMutation = `
  mutation($assetId: String!, $certificateType: CertificateType!) {
    createCertificateDownload(assetId: $assetId, certificateType: $certificateType)
  }
`;

export const registerInstallerMutation = `
  mutation($registrationRequest: RegistrationRequestInput) {
    registerAuthenticatedInstaller(registrationRequest: $registrationRequest)
  }
`;

export const removeInstallerFromEmailMarketingMutation = `
  mutation($email: String!) {
    removeInstallerFromEmailMarketing(email: $email)
  }
`;

export const updateMaxAIRenewalDate = `
  mutation {
    updateMaxAIRenewalDate
  }
`;

export const saveAssetRegistrationMutation = `
  mutation($assetRegistration: InProgressAssetRegistrationInput) {
    saveAssetRegistration(assetRegistration: $assetRegistration) {
      id
    }
  }
`;

export const deleteAssetRegistrationMutation = `
  mutation($id: ID!) {
    deleteAssetRegistration(id: $id) {
      id
    }
  }
`;

export const acknowledgeFailedRegistrationMutation = `
  mutation($assetRegistrationId: String!) {
    acknowledgeFailedRegistration(assetRegistrationId: $assetRegistrationId)
  }
`;

export const unlinkBenchmarkAccountMutation = `
  mutation {
    unlinkBenchmarkAccount
  }
`;

export const submitBenchmarkRegistrationMutation = `
  mutation($benchmarkRegistration: String!) {
    submitBenchmarkRegistration(benchmarkRegistration: $benchmarkRegistration)
  }
`;

export const submitWorkOrdersUpdate = `
  mutation($workOrderIds: [String!], $acceptedTerms: Boolean) {
    claimWorkOrders(workOrderIds: $workOrderIds, acceptTerms: $acceptedTerms)
  }
`;

export const completeWorkOrder = `
  mutation($workOrderId: String!, $jobReport: JobReportInput!) {
    completeWorkOrder(workOrderId: $workOrderId, jobReport: $jobReport) {
      jobReportId
      images {
        filename
        dataUrl
      }
    }
  }
`;

export const unclaimWorkOrder = `
  mutation($workOrderId: String!) {
    unclaimWorkOrder(workOrderId: $workOrderId)
  }
`;

export const submitInvoice = `
  mutation($invoiceNumber: String!, $file: UploadInput!) {
    submitInvoice(invoiceNumber: $invoiceNumber, file: $file) {
      createdDate
      id
      name
    }
  }
`;

export const saveBankDetailsMutation = `
  mutation($bankDetails: BankDetailsInput!) {
    saveBankDetails(bankDetails: $bankDetails)
  }
`;

export const createInvoiceDownloadMutation = `
  mutation($invoiceId: String!) {
    createInvoiceDownload(invoiceId: $invoiceId)
  }
`;

export const enrolPremierClubMutation = `
  mutation {
    enrolPremierClub
  }
`;
