import { API, Auth } from "aws-amplify";

export const getUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });

    return user;
  } catch (error) {
    return undefined;
  }
};

export const login = async (username, password) => {
  return await Auth.signIn(username, password);
};

export const logout = async () => {
  await Auth.signOut();
};

export const confirmSignUp = async (username, verificationCode) => {
  await Auth.confirmSignUp(username, verificationCode);
  await API.post("UnauthenticatedAPI", "/verify-installer-registration", {
    body: {
      email: username,
    },
  });
};

export const forgotPassword = async (email) => {
  await API.post("UnauthenticatedAPI", "/forgot-password", {
    body: {
      email,
    },
  });
};

export const forgotPasswordSubmit = async (email, code, newPassword) => {
  await Auth.forgotPasswordSubmit(email, code, newPassword);
};

export const changePassword = async (currentPassword, newPassword) => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(cognitoUser, currentPassword, newPassword);
};

export const requestChangeEmail = async (newEmail) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    email: newEmail,
  });
};

export const verifyChangeEmail = async (verificationCode) => {
  await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode);
};

export const passwordFormat =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.,\\[\]<>{}"~'`()§/|#?!@$%^&*-]).{8,}$/;
