import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export default function useStepNavigator(props = {}) {
  const expectingTransition = useRef(false);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(
    props.currentStep || {
      index: 0,
      id: "",
    }
  );

  const goToId = useCallback(
    (id) => {
      const selectedStep = steps.find((x) => x.id === id);

      if (selectedStep) {
        expectingTransition.current = true;
        setCurrentStep(steps[selectedStep.index]);
      }
    },
    [steps]
  );

  const goBack = useCallback(() => {
    if (currentStep.index > 0) {
      expectingTransition.current = true;
      setCurrentStep(steps[currentStep.index - 1]);
    }
  }, [currentStep, steps]);

  const goToNext = useCallback(() => {
    expectingTransition.current = true;
    setCurrentStep(steps[currentStep.index + 1]);
    window.scrollTo(0, 0);
  }, [currentStep, steps]);

  const StepNavigator = useMemo(() => {
    const StepNavigatorComponent = ({ children }) => {
      const renderedChildren = React.Children.toArray(children).filter((x) => x !== null);

      useEffect(() => {
        if (steps.length !== renderedChildren.length) {
          const updatedSteps = renderedChildren.map((child, index) => ({
            index,
            id: child.props.id || index,
          }));

          let currentChild = updatedSteps.find(
            (x) => x.id === currentStep.id
          );

          const previousChildIndex = steps.findIndex((x) => x.id === currentStep.id);
          if (!currentChild && previousChildIndex !== -1 && updatedSteps.length < steps.length) {
            for (let i = previousChildIndex; i < steps.length; i++) {
              const idx = updatedSteps.findIndex((x) => x.id === steps[i].id);

              if (idx !== -1) {
                currentChild = updatedSteps[idx];
                break;
              }
            }
          }

          setSteps(updatedSteps);

          if (!expectingTransition.current && currentChild) {
            setCurrentStep(currentChild);
          }
        } else {
          expectingTransition.current = false;
        }
      }, [children]); // eslint-disable-line react-hooks/exhaustive-deps

      return renderedChildren[currentStep.index];
    };

    return StepNavigatorComponent;
  }, [currentStep, steps, setSteps]);

  return {
    StepNavigator,
    goToId,
    goBack,
    goToNext,
    currentStep,
    totalSteps: steps.length,
  };
}
